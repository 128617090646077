@import "../../styles/index.scss";
.LandingPage {
  background-color: $whiteBG;
  color: $backgroundContrast;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.backgroundd {
  margin-top: 290px;
  width: 100vw;
  height: 600px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url("../../utils/graphics/hero2.svg");
}

.errorContainer {
  background-color: $main;
  color: $mainContrast;
  padding: 1em;
}

.spacerBlue {
  padding-top: 10%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../utils/graphics/blueWaves.svg");
}

.spacerBlueFlipped {
  padding-top: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100vw 100vh;
  background-image: url("../../utils/graphics/blueWaves2.svg");
  transform: rotate(180deg);
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $blue;
  min-height: 600px;
  width: 100%;
  .heroHeader {
    z-index: 1;
    width: 100%;
    height: 100%;
    max-width: calc(1024px + 4em);
    margin: 2em 0;
    padding: 0 2em;

    display: flex;
    justify-content: space-between;
  }
  .heroContent {
    z-index: 1;
    width: 100%;
    height: 100%;
    max-width: calc(1024px + 4em);
    margin: 2em 0;
    padding: 0 2em;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    .imgBox {
      flex-basis: 341px;
      flex-grow: 2;
      flex-shrink: 1;
      max-width: 33%;
      min-width: 275px;
      height: 100%;
    }
    .textBox {
      flex-basis: 341px;
      flex-grow: 1;
      flex-shrink: 2;
      max-width: 75ch;
      height: 100%;
      padding-right: 5em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .descriptiveText {
        justify-self: flex-end;
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 2em;
      }
    }
  }
}

.content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
  padding: 0 2em;
  max-width: calc(1024px + 4em);
  color: black;
  width: 100%;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  width: 100%;
}

.cardBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 300px;
  height: 400px;
  border-radius: 30px;
  padding: 3em;
  background-color: white;
  box-shadow: 6px 9px 15px 0px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  color: black;
  transition-duration: 100ms;
}
.cardBox:hover {
  transform: scale(105%);
}
.cardBox:nth-child(3) {
  background-color: grey;
}
.cardBox:nth-child(3):hover {
  transform: scale(100%);
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.ill {
  width: 100%;
  height: fit-content;
  min-width: 275px;
  max-height: 250px;
}

.bio {
  padding: 20em 0;
  width: 100%;
  display: flex;
  min-height: fit-content;
  gap: 2em;
  justify-content: space-between;
  .textContainer {
    display: flex;
    width: 100%;
    gap: 2em;
    height: fit-content;
  }
  .textBox {
    flex-shrink: 1;
    max-width: 75ch;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
  }
}
.verticalLine {
  width: 2px;
  min-width: 2px;
  min-height: 100%;
  background-color: $blue;
  border-radius: 2px;
}
.horizontalLine {
  width: 100%;
  height: 2px;
  background-color: $blue;
  border-radius: 2px;
  display: none;
}
.bioImg {
  height: 400px;
  width: 300px;
  min-width: 300px;
  background-image: url("../../utils/media/martin.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  clip-path: url(#svgPath);
}

form {
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 100%;
  margin-bottom: 16em;
  label {
    width: 100%;
    display: flex;
    flex-direction: column;
    p {
      padding-bottom: 0.25em;
    }
    .length {
      font-size: 1em;
      align-self: flex-end;
      padding-top: 0.25em;
    }
    .lengthError {
      font-size: 1em;
      align-self: flex-end;
      padding-top: 0.25em;
      color: red;
    }
  }
  input {
    width: 100%;
    border-radius: 5px;
    padding: 1em;
    border-style: solid;
    font-size: 1em;
  }
  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 12em;
    min-height: 6em;
    border-radius: 5px;
    border-style: solid;
    padding: 1em;
    font-size: 1em;
  }
  .loadingText {
    justify-self: center;
    align-self: center;
  }
  .errorText {
    color: red;
    align-self: center;
  }
  .feedback {
    align-self: center;
  }
  a {
    transition-duration: 100ms;
  }
  a:hover {
    transform: scale(110%);
    cursor: pointer;
  }
  button {
    align-self: center;
    background-color: $blue;
    color: white;
    padding: 1em;
    border-style: none;
    border-radius: 20px;
    width: 100%;
    max-width: 400px;
    box-shadow: 6px 9px 15px 0px rgba(0, 0, 0, 0.3);
    transition-duration: 100ms;
    font-weight: 900;
    text-transform: uppercase;
  }
  button:hover {
    transform: scale(105%);
    cursor: pointer;
  }
  .disabled {
    align-self: center;
    background-color: grey;
    color: white;
    padding: 1em;
    border-style: none;
    border-radius: 20px;
    width: 100%;
    max-width: 400px;
    box-shadow: 6px 9px 15px 0px rgba(0, 0, 0, 0.3);
    transition-duration: 100ms;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 2em;
  background-color: $blue;
  color: white;
  min-height: 100px;
  width: 100%;
}

@media only screen and (max-width: 855px) {
  .bio {
    flex-direction: column;
    justify-content: flex-start;
  }
  .verticalLine {
    display: none;
  }
  .horizontalLine {
    display: initial;
  }
  .bioImg {
    align-self: center;
    margin-bottom: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .hero {
    .heroContent {
      .imgBox {
        align-items: center;
        justify-content: center;
        padding-top: 5em;
        max-width: 100%;
      }
      .textBox {
        align-items: center;
        justify-content: center;
        padding: 0;
        max-width: 100%;
      }
    }
  }
  .bio .textContainer {
    height: 30em;
  }
}
