@import "../../styles/index.scss";
.FoodPage {
  background-color: $whiteBG;
  min-height: 100vh;
  color: black;
  display: grid;
  gap: 2em;
  grid-template-columns: 1fr minmax(300px, 1024px) 1fr;
  grid-template-areas:
    "Header Header Header"
    ". Main ."
    "Footer Footer Footer";
}

.main {
  grid-area: Main;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;

  article {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 6px 9px 15px 0px rgba(0, 0, 0, 0.3);

    .img {
      width: 100%;
    }
    .textBox {
      padding: 1em;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1ch;
      padding-top: 1em;
    }
    li {
      margin-left: 1.5ch;
    }
  }
}

.header {
  grid-area: Header;
  background-color: $blue;
  color: white;
  height: 4em;
  padding: 1em;
  text-align: center;
}

.footer {
  grid-area: Footer;
  background-color: $blue;
  color: white;
  min-height: 4em;
  padding: 1em;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .main {
    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
