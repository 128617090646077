* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Fredoka-Light", "Fredoka-Regular", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Fredoka-Light";
  src: local("Fredoka-Light"),
    url(./fonts/Fredoka/Fredoka-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Fredoka-Regular";
  src: local("Fredoka-Regular"),
    url(./fonts/Fredoka/Fredoka-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Fredoka-Medium";
  src: local("Fredoka-Medium"),
    url(./fonts/Fredoka/Fredoka-Medium.ttf) format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
