@import "./Colors.scss";

.title {
  font-size: 50px;
  font-weight: 900;
  text-transform: uppercase;
}
.subtitle {
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
}
p {
  font-size: 20px;
  font-weight: 600;
}
.flipped {
  transform: rotate(180deg);
}
button,
input,
select,
textarea,
a {
  font-family: inherit;
  font-size: 100%;
}
