@import "../../styles/index.scss";

.link {
  margin: 15px 10px;

  .cardContainer {
    display: flex;
    flex-flow: column wrap;
    transition-duration: 100ms;
    text-align: center;
    height: 380px;
    width: 200px;
    background-color: $main;
    border-radius: 20px;
    padding-bottom: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .poster {
      object-fit: scale-down;
      width: 100%;
    }
    .text {
      flex: 1;
      color: white;
      padding: 5px 10px;
      font-size: 18px;
      font-family: "Fredoka-Light";
      overflow: hidden;
      font-weight: bold;
    }
  }
}
.link:hover {
  .cardContainer {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(255, 255, 255, 0.19);
    transform: scale(110%);
    animation-duration: 500ms;
  }
}

@media only screen and (max-width: 800px) {
  .cardContainer {
    width: 100px;
  }
}
