@import "../../styles/index.scss";
.MovieDetailsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: 100%;
  background-color: $background;
  color: $backgroundContrast;
}
.content {
  padding: 2em;
  width: 100%;
  max-width: calc(1024px + 4em);
}

.container {
  padding: 2em 0;
  gap: 2em;
  display: flex;
  flex-direction: column;
}

.hero {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  img {
    flex: 0 1 300px;
  }
  .details {
    flex: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 300px;
    gap: 10px;
    font-size: large;
  }
}
.title {
  font-size: xx-large;
}
.tagline {
  font-size: x-large;
}

.more {
  display: flex;
  gap: 2em;
  padding: 20px 0;
  overflow: scroll;
  justify-content: flex-start;
  .actorCard {
    height: 300px;
    width: 250px;
    min-width: 250px;
    background-color: red;
  }
}

.line {
  height: 5px;
  width: 100%;
  background-color: $detail;
}
