@import "../../styles/index.scss";

.default {
  height: 5px;
  width: 100%;
  background-color: $detail;
}

.rounded {
  height: 5px;
  width: 100%;
  background-color: $detail;
  border-radius: 10px;
}

.thin {
  height: 3px;
  width: 100%;
  background-color: $detail;
}

.blue {
  height: 2px;
  width: 100%;
  background-color: blue;
}
