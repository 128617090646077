@import "../../styles/index.scss";
.FoodPage {
  background-color: $whiteBG;
  min-height: 100vh;
  color: black;
  display: grid;
  gap: 2em;
  grid-template-columns: 1fr minmax(300px, 1024px) 1fr;
  grid-template-areas:
    "Header Header Header"
    ". Main ."
    "Footer Footer Footer";
}

.main {
  grid-area: Main;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
}

.header {
  grid-area: Header;
  background-color: $blue;
  color: white;
  height: 4em;
  padding: 1em;
  text-align: center;
}

.footer {
  grid-area: Footer;
  background-color: $blue;
  color: white;
  min-height: 4em;
  padding: 1em;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .main {
    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
