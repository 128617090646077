@import "../../styles/index.scss";
.backBtn {
  z-index: 1;
  align-self: flex-start;
  justify-self: flex-start;
  position: absolute;
  margin-top: 0.5em;
  margin-left: 1em;
  top: 0;
  .backLink {
    width: fit-content;
    text-decoration: none;
    color: $backgroundContrast;
    display: flex;
    align-items: center;
    .roundArrow {
      margin-right: 10px;
      transform: rotate(90deg) scale(75%);
      transition-duration: 100ms;
    }
  }
  .backLink:hover {
    color: $activeText;
    .roundArrow {
      transform: rotate(90deg) scale(95%);
    }
  }
}
