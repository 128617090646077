@import "../../styles/index.scss";
.footer {
  background-color: $main;
  color: $mainContrast;
  font-size: 15px;
  width: 100%;
  min-height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  a {
    text-decoration: none;
    color: $mainContrast;
  }
  .copyRight {
    padding-top: 10px;
  }
}
