// --- --- --- --- --- --- --- ---
// --- Colors - Name and HEX ---
$tuftsBlue: #3c91e6;
$blackCoffee: #161118;
$babyPowder: #f9f7f3;
$brickRed: #651b24;
$customRed: #ff4b3e;

// Livlige farger
$purple: #6600ff;
$purpleDark: #06002f;
$purpleBG: #00050c;
$vine: #a00040;
$vineBG: #6d002c;
$whiteBG: #efffff;
$blue: #0066ff;
$lightBlue: #5c9dff;
// --- ---

// --- Page colors ---
$main: $brickRed;
$detail: $customRed;
$background: $blackCoffee;

// Text
$mainContrast: $babyPowder;
$backgroundContrast: $babyPowder;
$activeText: $customRed;
// --- ---
// --- --- --- --- --- --- --- ---

//First version
// $cultured: #f4f7f5;
// $maximumRed: #da1414;
// $goldFusion: #5d4911;
// $jet: #2f2f2f;
// $spaceCadet: #162a50;
// $oxfordBlue: #0d1930;
// $darkSienna: #210203;
// $fogra29: #040810;
// $fogra39: #08090a;

// $main: $darkSienna;
// $detail: $maximumRed;
// $background: $fogra29;
//
// Text
// $textLight: $cultured;
// $textDark: $fogra39;
