@import "../../styles/index.scss";
.header {
  height: 4.5em;
  background-color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
Canvas {
  height: Calc(100vh - 4.5em);
  background-color: aliceblue;
}
