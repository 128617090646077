@import "../../styles/index.scss";
.MoviePage {
  background-color: $background;
  color: $backgroundContrast;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;
}

.errorContainer {
  background-color: $main;
  color: $mainContrast;
  padding: 1em;
}

.hero {
  width: 100%;
  height: 90vh;
  min-height: 500px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9) 30%,
    rgba(0, 0, 0, 0.8) 45%,
    rgba(0, 0, 0, 0.8) 60%,
    rgba(0, 0, 0, 0.5) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;

  .video {
    position: absolute;
    width: 100%;
    height: 90vh;
    min-height: 500px;
    object-fit: cover;
    z-index: 0;
  }

  .textContainer {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .button {
    background-color: rgba($color: $background, $alpha: 0);
    border-style: none;
    border-radius: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 100ms;
    z-index: 1;
  }
  .button:hover {
    cursor: pointer;
    transform: scale(125%);
  }
}

.title {
  font-size: 50px;
  font-family: "Fredoka-Medium";
}
.subtitle {
  font-size: 30px;
  font-family: "Fredoka-Light";
}

.bodyContainer {
  background-color: $background;
  max-width: calc(1024px + 4em);
  width: 100%;
  padding: 100px 2em;
  display: flex;
  flex-direction: column;

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
  }
}
